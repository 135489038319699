import React, { type FC } from 'react'
import { Avatar, Box, Container, CssBaseline, Link, Typography } from '@mui/material'

export const App: FC = () => (
 <>
   <CssBaseline />
   <Box height="100vh">
     <Box height={280} sx={{ backgroundColor: '#f1f1f1' }}></Box>
     <Container sx={{ marginTop: '-125px' }}>
       <Box justifyContent="center" display="flex">
         <Avatar src="/me.jpeg" sx={{ width: 250, height: 250, border: '5px solid white' }} />
       </Box>
       <Box justifyContent="center" display="flex" flexDirection="column" textAlign="center">
         <Typography variant="h5">Laimonas Jočys</Typography>
         <Typography variant="subtitle1">Frontend Developer</Typography>
         <Typography variant="body2">Vilnius, Lithuania</Typography>
         <Box>
           <Link href="https://www.linkedin.com/in/laimonas-jocys" variant="caption" textTransform="uppercase">Read more</Link>
         </Box>
       </Box>
     </Container>
   </Box>
 </>
)
